// App.js
import React, { useState } from 'react';
import './App.css';
import TableForm from './TableForm';
import logo from './images/cmmstools.png'; // Assuming your logo file is in the src folder

function App() {
    const [tableName, setTableName] = useState('');
    const [isTableNameValid, setIsTableNameValid] = useState(false);

    const handleTableNameChange = (e) => {
        let value = e.target.value.replace(/[^a-zA-Z]/g, '');

        // Automatically capitalize the first letter
        if (value.length > 0) {
            value = value.charAt(0).toUpperCase() + value.slice(1);
        }

        const nameRegex = /^[A-Z][a-zA-Z]+$/; // Must start with a capital letter, no special chars or numbers
        setTableName(value);
        setIsTableNameValid(nameRegex.test(value));
    };

    return (
        <div className="App">
            {/* Header container for the logo */}
            <div className="header">
                <img src={logo} alt="Brand Logo" className="brand-logo" />
            </div>

            {/* Title section */}
            <div className="title-container">
                <h1>eMaint X5 Entity Builder</h1>
            </div>

            {/* Table Name Input */}
            <div className={"entity-name"}>
                <label className={"entity-name-label"}>Entity Name:</label>
                <input className={"entity-name-input-field"}
                    type="text"
                    value={tableName}
                    onChange={handleTableNameChange}
                    placeholder="Enter Table Name"
                />
                {!isTableNameValid && tableName.length > 0 && (
                    <p className="error-text">
                        Table name must start with a capital letter and contain no special characters or numbers.
                    </p>
                )}
            </div>

            {isTableNameValid && <TableForm tableName={tableName} isTableNameValid={isTableNameValid} />}
        </div>
    );
}

export default App;
